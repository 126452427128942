body, html {
  margin: 0;
  overflow: hidden;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  transition: opacity 400ms; }

body, .onepage-wrapper, html {
  display: block;
  position: static;
  padding: 0;
  width: 100%;
  height: 100%; }

.onepage-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  padding: 0;
  -webkit-transform-style: preserve-3d;
  .ops-section {
    width: 100%;
    height: 100%;
    position: relative; } }

.onepage-pagination {
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 5;
  margin: -54px 0 0 0;
  padding: 0;
  list-style: none;
  li {
    padding: 0;
    text-align: center;
    a {
      padding: 10px;
      width: 4px;
      height: 4px;
      display: block;
      &:before {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.45);
        content: ''; }
      &.active:before {
        left: 8px;
        margin-top: -4px;
        width: 10px;
        height: 10px;
        border: 1px solid rgba(255, 255, 255, 0.75);
        background: none; } } } }

.disabled-onepage-scroll {
  overflow: auto;
  .wrapper {
    overflow: auto; }
  .onepage-wrapper {
    .ops-section {
      position: relative !important;
      top: auto !important; }
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
    -ms-transform: none !important;
    min-height: 100%; }
  .onepage-pagination {
    display: none; } }

body.disabled-onepage-scroll, .disabled-onepage-scroll .onepage-wrapper, html {
  position: inherit; }
