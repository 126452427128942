
@import "bourbon/bourbon";
@import "vendor/magnific-popup";
@import "vendor/bootstrap";
@import "vendor/onepage-scroll";
@import "vendor/_media-queries";
@import "components/_mixins";
@import "components/_vars";
@import "components/_reset";
@import "components/_convenience";
@import url(http://fonts.googleapis.com/css?family=Lato:100,100italic,200,200italic,300,300italic,400,400italic,800,800italic|Open+Sans:100,200,400,300,700,600|Vollkorn);


/////////////////////////////////////////////////
// ABSTRACTIONS
/////////////////////////////////////////////////


.list-reset {
  @include list-reset; }

.page-layer {
  @include page-layer; }

.lead {
  line-height: 1.6; }

.comp-key {
  color: $comp_key; }

.comp-key-dark {
  color: $comp_key_dark; }

a.number {
  text-decoration: none !important;
  border-bottom: none !important; }

strong {
  font-weight: 300; }

/////////////////////////////////////////////////
// STRUCTURE
/////////////////////////////////////////////////

.main {
  @include transition(opacity 500ms ease-out, transform 1s ease);
  opacity: 0; }

.preload * {
  @include transition(none !important); }

.iconr {
  @include transition(all 1s ease);
  display: inline-block;
  width: 28px;
  height: 28px; }

.iconr-facebook {
  background: url(../images/social-facebook@2x.png) no-repeat center center;
  background-size: 28px 28px; }

.iconr-twitter {
  background: url(../images/social-twitter@2x.png) no-repeat center center;
  background-size: 28px 28px; }

.iconr-instagram {
  background: url(../images/social-instagram@2x.png) no-repeat center center;
  background-size: 28px 28px; }

.iconr-tumblr {
  background: url(../images/social-tumblr@2x.png) no-repeat center center;
  background-size: 28px 28px; }

.viewing-page-1,
.viewing-page-5 {
  .iconr-facebook {
    background-image: url(../images/social-facebook-black@2x.png); }
  .iconr-twitter {
    background-image: url(../images/social-twitter-black@2x.png); }
  .iconr-instagram {
    background-image: url(../images/social-instagram-black@2x.png); }
  .iconr-tumblr {
    background-image: url(../images/social-tumblr-black@2x.png); } }

/////////////////////////////////////////////////
// $COMPONENTS
/////////////////////////////////////////////////

%nav-link {
  @include transition(color 150ms ease, border 150ms ease);
  display: block;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1); } }

%nav-link.active {
  background-color: rgba(0, 0, 0, 0.3); }

%fixed-menu {
  @include transition(color 700ms ease, border 700ms ease);
  position: fixed;
  z-index: 1000;
  height: 44px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1rem;
  width: 100%; }

.primary-menu {
  @include clear;
  @include transition(color 300ms ease, border 300ms ease);
  @include user-select(none);
  opacity: 0;
  line-height: 45px;
  ul {
    float: left;
    height: 44px;
    width: 80%; } }

.social-footer {
  @include transition(color 1s ease);
  @extend %fixed-menu;
  @include clear;
  opacity: 0;
  bottom: 0;
  left: 0;
  padding: 0 16px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  height: 50px;
  ul {
    max-width: 70%;
    margin: 0 auto;
    opacity: 0.9;
 }    // border-top: 1px solid rgba(0,0,0,.2);
  li {
    display: inline-block;
    position: relative;
    margin: 0; }
  li.address {
    margin: 0 12px;
    font-weight: 300;
    text-align: right;
    line-height: 28px;
    font-size: 1em; }
  a {
    color: rgba(255, 255, 255, 0.85); }
  .social-icon {
    top: 8px;
    &.instagram,
    &.tumblr,
    &.facebook,
    &.twitter {}
    &.instagram {
      margin-left: 20px; }
    &.tumblr {
      margin-left: 0; }
    &.facebook {
      margin-left: -5px; }
    &.twitter {
      margin-left: 0px; } } }

.scrolling {
  .social-footer {
    opacity: 0;
    @include animation(none); } }

.onepage-pagination {
  position: absolute;
  opacity: 0;
  top: 50%;
  margin-top: -150px;
  height: 300px;
  list-style: none;
  @include transition(position 1s ease); }

.onepage-pagination li a,
.onepage-pagination li a:before,
.onepage-pagination li a.active:before {
  @include transition(background 400ms ease, border-color 400ms ease); }

/////////////////////////////////////////////////
// Bubble pagination

.onepage-pagination {
  z-index: 30; }

.onepage-pagination.slide-away {
  right: -232px; }

.viewing-page-5 {
  .primary-menu {
    background-color: rgba(0, 0, 0, .02);
    border-color: rgba(0, 0, 0, 0.3);
    a {
      &:hover {
        background-color: rgba(0, 0, 0, .1); }
      &.active {
        background-color: rgba(0, 0, 0, .05);
        &:hover {
          background-color: rgba(0, 0, 0, .05); } } } }

  .onepage-pagination li a:before {
    background: $grey_60; }
  .onepage-pagination li a.active:before {
    border-color: $grey_60;
    background: none; } }

/////////////////////////////////////////////////
// CONTENT
/////////////////////////////////////////////////


%flex-pane {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center; }

%pane {
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden; }

%saturated-pane {
  @extend %pane;
  background: $venture_red;
  color: $white;
  text-align: left;
  font-size: 1.5em;
  letter-spacing: .1em;
  font-weight: 200;
  @include hidpi {
    font-weight: 300; }
  strong {
    font-weight: 300;
    @include hidpi {
      font-weight: 500; } }
  a {
    color: $white;
    text-decoration: none;
    border-bottom: 1px solid $white; } }

%dark-pane {
  @extend %pane;
  -webkit-font-smoothing: antialiased;
  background: shade($comp_key, 90%);
  color: $grey_10;
  font-weight: 400;
  strong {
    font-weight: 600; }
  a {
    color: $comp_key; } }

.table-icon {
  text-align: center; }

.flex-section {
  @extend %flex-pane;
  padding: 0 80px;
  width: 100%;
  height: 100%; }

.inner-flex {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center; }

.constrained-single {
  max-width: 90%; }

.main {
  @include transition(opacity 1s ease);
  z-index: 10; }

.hamburger {
  @include transition(all 1s ease);
  position: absolute;
  z-index: 100;
  top: 20px;
  left: 0;
  width: 80px;
  height: 60px;
  font-size: 3.5rem;
  font-weight: 800;
  opacity: .6;
  a {
    display: block;
    text-align: center;
    color: #fff;
    text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.8); } }

/////////////////////////////////////////////////
// SPECIFIC SECTIONS

.splash {
  background: url(../images/laser-cutter-2300w-20q.jpg) no-repeat center center;
  background-size: cover;
  .splash-logo {
    @include hidpi {
      max-width: 80%; } } }

.about {
  @extend %saturated-pane;
  font-family: $lato;
  line-height: 1.6em;
  small {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8em;
    a {
      color: rgba(255, 255, 255, 0.8); } }
  strong {
    font-weight: 400; }
  .constrained-single {
    padding: 4em 5em;
    border-radius: 2px;
    margin-bottom: 0; } }

.samples {
  @extend %dark-pane;
  flex-flow: row wrap;
  overflow: hidden; }

.instagram-flex {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  max-width: 100%;
  width: 100vh;
  max-height: 75vh;
  clear: both;
  overflow: hidden; }

.instagram-thumb {
  float: left;
  max-width: 25vh;
  width: 25%;
  padding: 5px;
  &:nth-child(4n+1) {
    clear: both; }
  img {
    border-radius: 3px; } }

.capabilities {
  @extend %dark-pane;
  .info {
    flex: 2;
    order: 1;
    margin-right: 5%;
    min-width: 200px;
    max-width: 460px;
    p {
      margin-top: 2em;
      font-size: 1.1rem; } }
  .material-matrix {
    flex: 2;
    order: 2;
    font-family: $lato;
    font-weight: 900;
    min-width: 240px;
    max-width: 620px; }
  .material-name {
    text-transform: uppercase; }
  .cell {
    float: left;
    margin-bottom: 1em;
    padding: 0 1em;
    min-width: 120px;
    width: 50%;
    border-radius: 3px;
    text-align: left;
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      cursor: help; } }
  .value {
    font-family: $lato;
    padding: 4px 0;
    color: $grey_10;
    font-weight: 400;
    font-size: 1.7em;
    line-height: 1.5em; }
  .term {
    font-family: $lato;
    padding: 4px 0;
    color: shade($comp_key, 5%);
    font-weight: 900; }
  h3 {
    padding-left: .5em;
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-radius: 3px;
    border-bottom: 1px solid $grey_70; }
  .table thead > tr > th {}
  .table tbody > tr > th,
  .table tfoot > tr > th,
  .table thead > tr > td,
  .table tbody > tr > td,
  .table tfoot > tr > td {
    border: none;
    font-size: .9em;
    padding: 6px 8px; }
  .table tbody > tr > th {
    padding-bottom: 1rem;
    font-style: italic;
    color: rgba(255,255,255,.7); }
  .table-hover > tbody > tr:hover > td,
  .table-hover > tbody > tr:hover > th {
    background-color: $grey_80; } }

.inventory {
  @include clear;
  background: $grey_5;
  padding: 0 48px;
  li {
    float: left;
    padding: 5px;
    max-width: 30vh;
    width: 13%;
    cursor: pointer;
    text-align: center;
    a {
      display: block; } }
  .tooltip {
    margin-left: 1px;
    margin-top: -32px; }
  .tooltip.top {
    margin-top: -4px; }
  .tooltip.bottom {
    margin-top: 4px; } }

.guidelines {
  @extend %dark-pane;
  flex-flow: row wrap;
  font-family: $opensans;
  font-size: 1.15rem;
  .laser-outline {
    flex: 1 1 35%;
    display: none;
    max-width: 280px;
    margin-right: 5%;
    opacity: .6;
    text-align: right; }
  .info {
    flex: 1 1 55%;
    max-width: 500px;
    min-width: 400px;
    p {
      margin-bottom: 2em; } }
  h3 {
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: 1px solid $grey_80; }
  ul.guideline-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 0.5em;
      text-indent: -1em; }
    li:before {
      content: "·";
      color: $grey_50;
      margin-right: .7em; } }
  em {
    font-weight: 500;
    font-style: normal; }

  .nav-tabs {
    border-bottom: 1px solid $grey_70; }
  .nav > li > a {
    color: $comp_key_dark;
    font-size: .85rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: .1em; }
  .nav > li > a:hover,
  .nav > li > a:focus {
    color: $comp_key;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0; }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: $grey_20;
    cursor: default;
    background-color: #1b1b1b;
    border: 1px solid $grey_70;
    border-radius: 0;
    border-bottom-color: #1b1b1b; }
  .tab-content {
    padding: 1em 2em;
    border-style: solid;
    border-color: $grey_70;
    border-width: 0 1px 1px 1px;
    min-height: 200px;
    margin-bottom: 2em;
    background-color: #1b1b1b; } }

.pricing {
  @extend %saturated-pane;
  font-family: $lato;
  padding: 0 120px;
  .price {
    font-family: $helvetica;
    font-weight: 100;
    @include hidpi {
      font-weight: 200; }
    flex: 1 1 25%;
    margin-right: 8%;
    max-width: 240px;
    text-align: right;
    letter-spacing: -1px;
    font-style: italic;
    font-size: 5em;
    line-height: 1;
    margin-top: -.15em;
    color: $white;
    .superscript {
      position: relative;
      top: 0.8em;
      vertical-align: text-top;
      letter-spacing: 1px;
      font-size: 0.3em; }
    .cents {
      margin-left: 0.2em;
      border-bottom: 4px double $white; } }
  .per-hour {
    color: $grey_10;
    margin-top: 0.5em;
    padding-top: 1em;
    margin-bottom: 0.5em;
    font-size: .2em;
    letter-spacing: 1px;
    font-style: italic; }
  .aside-info {
    flex: 1 1 70%;
    max-width: 600px;
    padding-left: 6%;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    > strong {
      display: block;
      margin-bottom: 1em;
      font-family: $lato;
      font-weight: 400;
      font-size: 1.4em; }
    p {
      font-size: 1.3rem;
      line-height: 1.9em;
      margin-bottom: 1.5rem; }
    em {
      font-weight: 200; } }
  .credit-cards {
    background: transparent url(../images/credit-card-icons.png) no-repeat top left;
    width: 107px;
    height: 17px;
    background-size: 107px 17px;
    margin: 1.3rem 0 0; } }

.client-logos {
  @extend %saturated-pane;
  font-family: $lato;
  padding: 0 120px;
  flex-flow: row wrap;
  .client-logo-single {
    width: 50%;
    margin: 2em 0;
    padding: 0 3rem;
    text-align: center; } }


.italic {
  font-style: italic; }

.u {
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
  padding-bottom: 0.2em; }

.glyphicon-large {
  font-size: 1.5em; }

/////////////////////////////////////////////////
// MODALS
/////////////////////////////////////////////////

.swatch-nav {
  @include hover-active-focus(color, $grey_80);
  color: $comp_key_dark;
  text-transform: uppercase;
  cursor: pointer; }

.swatch-nav.disabled {
  opacity: .2;
  cursor: default; }

.swatch-index-footer {
  text-align: center;
  font-weight: 600; }

.modal-backdrop.in {
  opacity: 0.7; }

.swatch-modal {
  .modal-dialog {
    padding: 0;
    width: 100%;
    height: 100%;
    @include transition(all 500ms ease); }
  .modal-content {
    position: relative;
    height: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: $white_true;
    padding-bottom: 3rem;
    @include transition(all 500ms ease); }
  .modal-header {
    border-bottom: 1px solid #ddd;
    background: #f7f7f7;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 1.2rem; }
  .modal-title {
    color: $black;
    text-transform: uppercase; }
  .modal-body {
    @include clear;
    @include transition(all 500ms ease);
    padding: 0 10px; }
  .modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 4px solid $grey_90;
    background: #f7f7f7;
    padding: 10px 20px 15px;
    font-weight: 800;
    font-size: 1.1rem;
    letter-spacing: 1px;
    margin-top: 0;
    @include text-shadow-white;
    a {
      font-weight: 600; }
    .glyphicon {
      position: relative;
      top: 0.1rem; } }
  .image {
    float: none;
    margin-right: 0;
    width: 100%;
    text-align: center;
    max-height: 40vh;
    img {
      max-width: 40vh; } }
  header {
    display: block;
    font-size: 1.1rem;
    letter-spacing: 1px;
    color: $grey_70;
    text-transform: uppercase;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 3px double $grey_20; }
  .price-points {
    @include list-reset;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    li {
      @include clearfix;
      display: block;
      border-bottom: 1px dotted $grey_20;
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:last-child {
        border-bottom: 3px double $grey_20;
        margin-bottom: 20px; } }
    b {
      float: left;
      text-align: left;
      font-weight: normal;
      padding: 0 1rem; }
    strong {
      float: right;
      text-align: right;
      font-weight: 500; }
    em {
      opacity: .7;
      font-size: 0.8em;
      vertical-align: bottom;
      margin-left: 0.5em;
      sup {
        vertical-align: super; } } }
  .details {
    float: none;
    p {
      color: $grey_60;
      font-size: 1.2rem;
      line-height: 1.7;
      padding: 0 1rem 2rem 1rem; } }
  .close {
    @include text-shadow-white;
    color: $grey_40;
    font-size: 2.1rem;
    opacity: 1;
    &:hover {
      color: $grey_5; } } }

/////////////////////////////////////////////////
// OVERRIDES
/////////////////////////////////////////////////

// Bootstrap 3

.tooltip {
  font-size: 1rem; }

/////////////////////////////////////////////////
// ANIMATION
/////////////////////////////////////////////////

@include keyframes(fadeIn) {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }


@include keyframes(menuFadeIn) {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }


.fade-in-init {
  @include animation(fadeIn ease-in 600ms);
  @include animation-delay(1s);
  @include animation-fill-mode(forwards); }

.menu-fade-in-init {
  @include animation(menuFadeIn ease-in 400ms);
  @include animation-delay(1200ms);
  @include animation-fill-mode(forwards); }

.slide-away {
  margin-left: 220px; }

/////////////////////////////////////////////////
// Responsive HiDPI
/////////////////////////////////////////////////

// tablets
@media only screen and (min-width: 1px) and (max-width: 766px) {
  html {
    font-size: $font_percent * 0.9; }
  .social-footer {
    display: none; }
  .primary-menu {
    background: url(../images/vl-icon-white.png) no-repeat 90px 520px;
    background-size: 36px 26px;
    @include clear;
    ul {
      @include clear;
      height: 100%;
      width: 100%;
      float: none; }
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 220px;
    height: 100%;
    text-transform: uppercase;
    a {
      display: block;
      width: 100%;
      font-size: 1.4em;
      letter-spacing: 1px;
      color: #ccc;
      padding: 8px 12px 8px 40px;
      border-bottom: 1px solid #444; }
    a.active {
      background-color: #1b1b1b;
      color: #bbb; }
    .right {
      float: none;
      a {
        display: block; } }
    .glyphicon {
      display: none; } }
  .viewing-page-7 {
    .hamburger a {
      color: $grey_60; } }
  .about {
    padding-top: 0 !important;
    small {
      font-size: 1.3rem;
      font-weight: 400; }
    .constrained-single {
      padding: 0 10px;
      font-weight: 300; } }
  .onepage-pagination {
    height: 200px;
    margin-top: -100px; }
  .about,
  .pricing {
    font-size: 1.4em;
    font-weight: 300; }
  .capabilities {
    .info p {
      font-size: 1.7rem; } }
  .instagram-flex {
    margin: 0 auto;
    width: 100%;
    max-height: 75vh;
    clear: both;
    overflow: hidden; }
  .instagram-thumb {
    float: left;
    width: 33%;
    padding: 1%;
    &:nth-child(4n+1) {
      clear: none; }
    img {
      border-radius: 3px; } }
  .inventory {
    li {
      width: 33%; } }
  .swatch-modal {
    .close {
      @include no-select;
      font-size: 2.8rem;
      padding-left: 20px; } }
  .capabilities {
    .cell {
      float: none;
      width: auto; }
    .info {
      font-size: 1.3em; } }
  .guidelines {
    .info {
      min-width: 0;
      font-size: 1.1rem;
      p {
        font-size: 1.2rem; } }
    .need-help {
      display: none; }
    .tab-content {
      min-height: 300px; } }
  .pricing {
    padding-top: 0 !important;
    .price {
      font-size: 7rem;
      text-align: center;
      margin-bottom: 10px; }
    .aside-info {
      border: none; }
    .per-hour {
      margin-top: 0;
      padding-top: 0; } }
  .flex-section {
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px; }
  .modal-dialog {
    margin: 0; }
  .tooltip {
    font-size: 1.5rem; } }

// Desktop
@media screen and (min-width: 767px) {
  html {
    font-size: $font_percent * 1.1; }
  .mobile-only {
    display: none !important; }
  .hamburger {
    display: none; }
  .primary-menu {
    @include transition(color 700ms ease, border 700ms ease, background-color 1s ease);
    // background-color: rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.45);
    position: fixed;
    z-index: 1000;
    height: 44px;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 13px;
    line-height: 44px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    a {
      @include transition(background-color 250ms ease-out, color 1300ms ease-in, border 1300ms ease, line-height 300ms ease);
      padding: 0 24px;
      display: block;
      color: rgba(255, 255, 255, 0.85);
      text-align: center;
      text-transform: uppercase;
      background-color: transparent;
      &.active,
      &.active:hover {
        background-color: rgba(255, 255, 255, 0.1); }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1); } }
    li {
      display: block;
      float: left;
      width: (100% - 6%) / 6;
      max-width: 180px; }
    .right {
      float: right; } }
  .constrained-single {
    max-width: 1024px; }
  .guidelines {
    .tab-content {
      min-height: 290px; } }
  .onepage-pagination {
    right: 24px;
    li {
      height: 20px;
      min-height: 20px;
      margin: 20px 0; } }
  .viewing-page-2 {
    .social-footer {
      ul {
 } } }        // border-top: 1px solid rgba(255,255,255,0.5);
  .viewing-page-3,
  .viewing-page-4,
  .viewing-page-6 {
    .primary-menu {
      // background-color: rgba(255,255,255,.2);
      background-color: transparent;
      border-color: rgba(255, 255, 255, 0.2);
      a {
        color: $grey_30; } }
    .social-footer {
      ul {
        // border-color: rgba(255,255,255,.3);
        opacity: 0.65;
        text-shadow: 0 1px 0 $black; } } }
  .viewing-page-5 {
    .primary-menu {
      a {
        color: $grey_60; }
      a.active,
      a.active:hover {
        color: $grey_70; } }
    .social-footer {
      color: $grey_70;
      .social-icon {
        opacity: .5; }
      a {
        color: $grey_80; } } }
  .viewing-page-7 {
    .social-footer ul {
 } }      // border-top: 1px solid rgba(255,255,255,0.4);
  .viewing-page-1 {
    .primary-menu {
      border-color: transparent;
      text-shadow: 0 1px 6px rgba(0, 0, 0, 0.8); }
    .social-footer {
      color: $grey_80;
      a {
        color: $grey_90; } }
    .onepage-pagination li a:before {
      background: $grey_80; }
    .onepage-pagination li a.active:before {
      border-color: $grey_80;
      background: none; } }
  .swatch-modal {
    .modal-dialog {
      padding-top: 44px;
      max-width: 1080px;
      width: 70%;
      height: auto;
 }      // min-height: 55vh
    .modal-content {
      border-radius: 6px;
      box-shadow: 0 0 20px 5px rgba(0,0,0,.2);
      background: $white_true;
 }      // min-height: 55vh
    .modal-header {
      border-radius: 2px 2px 0 0; }
    .modal-title {
      color: $black;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.2rem; }
    .modal-body {
      padding: 5px 20px;
      @include clear; }
    .modal-footer {
      margin-top: 0; }
    .image {
      float: left;
      width: 60%; }
    .details {
      float: right;
      width: 36%;
      margin-right: 4%;
      p {
        line-height: 1.7; } }
    .close {
      @include text-shadow-white;
      color: $grey_40;
      font-size: 2rem;
      opacity: 1;
      &:hover {
        color: $grey_5; } } }
  .guidelines {
    .need-help {
      display: none; } } }

// Desktop small
@media only screen and (min-width: 767px) and (min-height: 700px) {
  html {
    font-size: $font_percent * 1.1; }
  .constrained-single {
    max-width: 900px; }
  .about {
    .constrained-single {
      max-width: 824px; } }
  .inventory {
    li {
      width: 16%; } }
  .guidelines {
    .need-help {
      display: block; }
    .info {
      max-width: 500px; } }
  .client-logos {
    .client-logo-single {
      width: 33%;
      margin: 2em 0;
      padding: 0 6rem;
      text-align: center; } } }

@media only screen and (min-width: 1000px) {
  .about {
    font-size: 1.6em;
    .constrained-single {
      max-width: 900px; } }
  .guidelines {
    .laser-outline {
      display: block; } }
  .guidelines {
    .info {
      max-width: 600px; } } }

// Desktop Medium
@media only screen and (min-width: 1200px) and (min-height: 700px) {
  html {
    font-size: $font_percent * 1.2; }
  .flex-section {
    padding: 0 80px; }
  .about {
    .constrained-single {
      font-size: 1.25em;
      max-width: 1600px;
      line-height: 1.3; } }
  .inventory {
    li {
      width: 16%; } }
  .guidelines {
    .laser-outline {
      flex: 1 1 45%;
      max-width: 400px; }
    .info {
      flex: 1 1 45%;
      max-width: 700px; } } }

// Desktop Large
@media only screen and (min-width: 1400px) and (min-height: 700px) {
  html {
    font-size: $font_percent * 1.35; }
  .flex-section {}
  .about {
    .constrained-single {
      font-size: 1.35em;
      max-width: 1200px; } } }

// Desktop Mode X-Large
@media only screen and (min-width: 1600px) and (min-height: 700px) {
  html {
    font-size: $font_percent * 1.5; }
  .primary-menu {
    height: 54px;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 16px;
    line-height: 54px; }
  .flex-section {}
  .about {
    .constrained-single {
      font-size: 1.6em;
      max-width: 1400px; } } }



// Desktop Mode X-Large
@media only screen and (min-width: 1800px) and (min-height: 700px) {
  html {
    font-size: $font_percent * 1.6; }

  .flex-section {}
  .about {
    .constrained-single {
      font-size: 1.45em;
      max-width: 1600px; } } }

// Desktop Mode XX-Large
@media only screen and (min-width: 2000px) and (min-height: 700px) {
  html {
    font-size: $font_percent * 1.7; }
  .flex-section {}
  .about {
    .constrained-single {
      font-size: 1.5em;
      max-width: 1800px; } } }


