// Headers
$h1_base_size: 3rem;
$h2_base_size: 2rem;
$h3_base_size: 1.6rem;
$h4_base_size: 1.2rem;
$h5_base_size: 1.1rem;
$h6_base_size: 0.9rem;

// Fonts
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$lato: "Lato", sans-serif;
$opensans: "Open Sans", sans-serif;
$vollkorn: "Vollkorn", serif;

// Families
$font_family: $opensans;
$header_font_family: $lato;
$meta_font_family: $vollkorn;

// Globals
$font_percent: 62.5%;
$line_height: 1.481;
$background_color: #fafafa;

// Greyscale Colors
$black: #1b1b1b;
$black_true: black;
$white: #fbfbfb;
$white_true: white;
$grey_5: darken($white, 5%);
$grey_10: darken($white, 10%);
$grey_15: darken($white, 15%);
$grey_20: darken($white, 20%);
$grey_30: darken($white, 30%);
$grey_40: darken($white, 40%);
$grey_50: darken($white, 50%);
$grey_60: darken($white, 60%);
$grey_70: darken($white, 70%);
$grey_80: darken($white, 80%);
$grey_90: darken($white, 90%);

// Saturated Colors
$red_medium: #c0392b;
$grass_medium: #27ae60;
$aqua_medium: #3498db;
$rust_medium: #d35400;
$violet_medium: #8e44ad;
$seafoam_medium: #1abc9c;
$clay_red_dull: #e67e22;
$ocean_deep_blue: #2980b9;
$teal_medium: #2ecc71;

// Monochromatic Color Theme (venture red)
//  http://colourco.de/complement/5/%23ea1f27

// Theme Colors
$venture_red_original: #d41f26;
$venture_red_shade: shade($venture_red_original, 4%);
$venture_red: $venture_red_shade;
$mono_black: shade($venture_red, 90%);
$key_color: $venture_red;
$comp_key: #1dcbc4;
$comp_key_dark: desaturate(#1dcbc4, 20%);

