html {
    overflow-x: hidden;
    height: 100%;
    font-size: $font_percent; }

body {
    overflow-x: hidden;
    height: 100%;
    font-size: 1em;
    color: $black;
    font-family: $font_family;
    line-height: $line_height;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    background-color: $grey_90;
    font-family: $opensans; }

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 300; }

h1, .h1s {
    font-size: $h1_base_size; }

h2, .h2s {
    font-size: $h2_base_size; }

h3, .h3s {
    font-size: $h3_base_size; }

h4, .h4s {
    font-size: $h4_base_size; }

h5, .h5s {
    font-size: $h5_base_size; }

h6, .h6s {
    font-size: $h6_base_size; }

h1, .h1s, h3, .h3s, h5, .h5s {
    font-family: $opensans;
    font-weight: 500; }

h2, .h2s, h4, .h4s, h6, .h6s {
    font-family: $lato;
    font-weight: 200; }

h3, h5 {
    color: $grey_40; }

hr {
    border-top: 1px solid $grey_20; }

hr.inverse {
    border-top: 1px solid $grey_70; }

a {
    @include hover-active-focus(text-decoration, none);
    color: $comp_key_dark; }

img {
    max-width: 100%; }

ul {
    @include list-reset; }
