
// 5px padding/margin adjustments
$properties: padding margin;
$directions: top right bottom left;

@each $prop in $properties {
    @each $direct in $directions {
        @for $i from 0 through 8 {
            .#{$prop}-#{$direct}-#{$i * 5} {
                #{$prop}-#{$direct}: #{$i * 5}px; } } } }

@for $i from 0 through 20 {
    .z-plane-#{$i} {
        z-index: $i; } }

.text-left {
    text-align: left; }

.text-center {
    text-align: center; }

.text-right {
    text-align: right; }

.clear {
    clear: both; }

.mobile-trim-inline {
    display: inline;
    @include iphone3 {
        display: none; }
    @include iphone4 {
        display: none; }
    @include iphone5 {
        display: none; } }
